<template>
    <div class="card st-framework-card">

        <div class="card-header">
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                        <font-awesome-icon :icon="icon" size="sm"/>
                    </td>
                    <td style="text-align: center;">
                        {{ $i18n.tc('translations.' + name, 2) }}
                    </td>
                    <td style="width: 50px;"/>
                </tr>
            </table>
        </div>

        <div class="card-body st-fadein-content" v-if="pageLoaded">

            <!-- HEADER -->

            <b-input-group size="sm" class="mb-3">
                <b-input-group-append class="ml-auto">
                    <b-button squared variant="success" v-on:click="fetchData">
                        {{ $i18n.t('translations.Refresh') }}
                    </b-button>
                </b-input-group-append>
            </b-input-group>

            <!-- TABLE -->

            <div class="row m-0 p-0">

                <!-- FIXED TABLE AREA -->

                <div class="col fixed-table-area">
                    <table class="table table-bordered table-striped inventory-forecast-table">
                        <thead>
                        <tr>
                            <th style="width: 28px;"/>
                            <template v-for="field in fields.fixedTableFields">
                                <th :key="'th-fixed-'+field.id" :class="field.thclass" :style="field.thstyle" :title="field.label">
                                    {{ field.label }}
                                </th>
                            </template>
                        </tr>
                        </thead>
                        <tbody v-if="products">
                        <template v-for="(product,productIndex) in products">
                            <tr :key="'tr-fixed-'+productIndex" :class="{'selected-row': (product.id === selectedRow)}"
                                v-on:click="selectTableRow(product)">
                                <td class="px-0" style="text-align: center;">
                                    <b-button squared variant="outline-danger" class="btn-sm px-1 py-0"
                                              v-on:click="removeProduct(product)">
                                        <font-awesome-icon :icon="['fas', 'minus']" size="sm"/>
                                    </b-button>
                                </td>
                                <template v-for="(field,fieldIndex) in fields.fixedTableFields">
                                    <template v-if="field.showintable">
                                        <td :key="'td-fixed-'+productIndex+'-'+fieldIndex"
                                            :class="field.tdclassfield ? product[field.tdclassfield] : field.tdclass"
                                            :style="field.tdstylefield ? product[field.tdstylefield] : field.tdstyle"
                                            :title="product[field.name]">
                                            <Field :field="field" :data="product" :state="state"/>
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

                <!-- SCROLLABLE TABLE AREA -->

                <div ref="scrollable-table-area" class="col scrollable-table-area">
                    <div ref="top-scrollbar-wrapper" class="top-scrollbar-wrapper"
                         v-on:scroll="scrollTopScrollBar">
                        <div class="top-scrollbar" :style="top_scrollbar_style"/>
                    </div>
                    <div ref="scrollable-area-wrapper" class="scrollable-area-wrapper"
                         v-on:scroll="scrollBottomScrollBar">
                        <div ref="scrollable-area">
                            <table class="table table-bordered table-striped inventory-forecast-table">
                                <thead ref="thead-scrollarea">
                                <tr>
                                    <template v-for="(order,orderIndex) in orders">
                                        <th :key="'th-scollable-'+orderIndex" class="p-1" style="vertical-align: top; width: 300px;">
                                            <div class="row">
                                                <div class="col" style="text-align: left;">
                                                    <b-button squared variant="outline-dark" class="btn-sm px-1 py-0"
                                                              v-on:click="shiftOrderLeft(order)">
                                                        <font-awesome-icon :icon="['fas', 'chevron-left']" size="sm"/>
                                                    </b-button>
                                                    <b-button squared variant="outline-danger" class="btn-sm px-1 py-0 ml-1"
                                                              v-on:click="removeOrder(order)">
                                                        <font-awesome-icon :icon="['fas', 'minus']" size="sm"/>
                                                    </b-button>
                                                </div>
                                                <div class="col">
                                                    {{ order.project_id.id }}
                                                </div>
                                                <div class="col" style="text-align: right;">
                                                    <b-button squared variant="outline-dark" class="btn-sm px-1 py-0"
                                                              v-on:click="shiftOrderRight(order)">
                                                        <font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"/>
                                                    </b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    {{ order.name }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    {{ order.work_plan_delivery_date }}
                                                </div>
                                            </div>
                                        </th>
                                    </template>
                                </tr>
                                </thead>
                                <tbody v-if="products">
                                <template v-for="(product,productIndex) in products">
                                    <tr :key="'tr-scrollable-'+productIndex" :class="{'selected-row': (product.id === selectedRow)}"
                                        v-on:click="selectTableRow(product)">
                                        <template v-for="(order,orderIndex) in orders">
                                            <td :key="'td-scrollable-'+productIndex+'-'+orderIndex">
                                                <div class="row no-gutters">
                                                    <div class="col pl-3" v-if="inventoryData(product.id, order.id, 'NEEDED') || inventoryData(product.id, order.id, 'USED')">
                                                        {{ inventoryData(product.id, order.id, 'NEEDED') }} ({{ inventoryData(product.id, order.id, 'USED') }})
                                                    </div>
                                                    <div class="col st-number" :class="inventoryData(product.id, order.id, 'CLASS')">
                                                        {{ inventoryData(product.id, order.id, 'INVENTORY') }}
                                                    </div>
                                                </div>
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- FOOTER -->

        <div class="card-footer light" v-if="pageLoaded">
            <b-input-group size="sm" class="mb-2">
                <b-input-group-append>
                    <ButtonAction :action="actions.addProductAction"
                                  v-on:add-product="addProduct"/>
                </b-input-group-append>
            </b-input-group>

            <b-input-group size="sm">
                <b-input-group-append>
                    <ButtonAction :action="actions.addOrderAction"
                                  v-on:add-order="addOrder"/>
                </b-input-group-append>
                <b-input-group-append class="ml-1">
                    <DropDownAction :action="actions.copyOrdersFromWorkPlanListAction"
                                    v-on:copy-all-orders="setOrdersFromWorkPlanList(true)"
                                    v-on:copy-not-assembled-orders="setOrdersFromWorkPlanList(false)"/>
                </b-input-group-append>
                <b-input-group-append class="ml-auto">
                    <b-button squared variant="danger" :disabled="!products" v-on:click="resetProducts">
                        {{ $i18n.tc('translations.Clear Product', 2) }}
                    </b-button>
                </b-input-group-append>
                <b-input-group-append class="ml-1">
                    <b-button squared variant="danger" :disabled="!orders" v-on:click="resetOrders">
                        {{ $i18n.tc('translations.Clear Order', 2) }}
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import DropDownAction from "@/components/actions/DropDownAction";
import FileDownload from "@/mixins/FileDownload";
import Field from "@/components/fields/Field.vue";
import ButtonAction from "@/components/actions/ButtonAction.vue";

export default {
    name: "InventoryForecast",
    components: {
        ButtonAction,
        Field,
        DropDownAction,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages', 'permissions'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            actions: null,
            fields: null,
            products: null,
            orders: null,
            data: null,
            selectedRow: null,
            top_scrollbar_style: '',
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        addOrder(data) {
            let orderIds = this.orders ? this.orders.map(order => order.id) : [];
            if (orderIds.indexOf(data.order_id) === -1) {
                orderIds.push(data.order_id);
                this.setOrders(orderIds);
            }
        },
        addProduct(data) {
            let productIds = this.products ? this.products.map(product => product.id) : [];
            if (productIds.indexOf(data.product_id) === -1) {
                productIds.push(data.product_id);
                this.setProducts(productIds);
            }
        },
        fetchData() {
            this.state.loading = true;
            this.$http.get(this.api + '/manage',).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.products = res.data.products;
                this.orders = res.data.orders;
                this.data = res.data.data;
                this.pageLoaded = true;
                this.state.loading = false;
                this.setTopScrollBarWidth();
            }).catch((error) => {
                this.data = null;
                console.log("InventoryForecast:fetchData():error:", error);
            });
        },
        inventoryData(productId, orderId, key) {
            if (this.data && (productId in this.data) && (orderId in this.data[productId])) {
                return this.data[productId][orderId][key];
            }
            return "?";
        },
        removeOrder(order) {
            this.data = null;
            let orderIds = this.orders ? this.orders.map(order => order.id) : [];
            let index = orderIds.indexOf(order.id);
            orderIds.splice(index, 1);
            this.setOrders(orderIds);
        },
        removeProduct(product) {
            let productIds = this.products ? this.products.map(product => product.id) : [];
            let index = productIds.indexOf(product.id);
            productIds.splice(index, 1);
            this.setProducts(productIds);
        },
        resetOrders() {
            this.setOrders(null);
        },
        resetProducts() {
            this.setProducts(null);
        },
        scrollBottomScrollBar() {
            let topScrollBar = this.$refs['top-scrollbar-wrapper'];
            let bottomScrollBar = this.$refs['scrollable-area-wrapper'];
            topScrollBar.scrollLeft = bottomScrollBar.scrollLeft;
        },
        scrollTopScrollBar() {
            let topScrollBar = this.$refs['top-scrollbar-wrapper'];
            let bottomScrollBar = this.$refs['scrollable-area-wrapper'];
            bottomScrollBar.scrollLeft = topScrollBar.scrollLeft;
        },
        selectTableRow(row) {
            this.selectedRow = row.id;
        },
        setOrders(orders) {
            this.state.loading = true;
            this.$http.post(this.api + '/set_orders', {
                orders: orders,
            }).then((res) => {
                this.data = null;
                this.orders = res.data.orders;
                this.state.loading = false;
                this.setTopScrollBarWidth();
            }).catch((error) => {
                console.log("InventoryForecast:setOrders():error:", error);
            });
        },
        setOrdersFromWorkPlanList(task_assembly_done) {
            this.state.loading = true;
            this.$http.post(this.api + '/set_orders_from_work_plan_list', {
                'task_assembly_done': task_assembly_done,
            }).then((res) => {
                this.data = null;
                this.orders = res.data.orders;
                this.state.loading = false;
                this.setTopScrollBarWidth();
            }).catch((error) => {
                console.log("InventoryForecast:setOrdersFromWorkPlanList():error:", error);
            });
        },
        setProducts(products) {
            this.state.loading = true;
            this.$http.post(this.api + '/set_products', {
                products: products,
            }).then((res) => {
                this.products = res.data.products;
                this.state.loading = false;
                this.setTopScrollBarWidth();
            }).catch((error) => {
                console.log("InventoryForecast:setProducts():error:", error);
            });
        },
        setTopScrollBarWidth() {
            this.$nextTick(function () {
                let scrollArea = this.$refs['thead-scrollarea'];
                if (scrollArea) {
                    this.top_scrollbar_style = 'width: ' + scrollArea.clientWidth + 'px;';
                }
            });
        },
        shiftOrderLeft(order) {
            this.data = null;
            let orderIds = this.orders ? this.orders.map(order => order.id) : [];
            let index = orderIds.indexOf(order.id);
            orderIds.splice(index, 1);
            if (index === 0) {
                orderIds.splice(orderIds.length, 0, order.id);
            } else {
                orderIds.splice(index - 1, 0, order.id);
            }
            this.setOrders(orderIds);
        },
        shiftOrderRight(order) {
            this.data = null;
            let orderIds = this.orders ? this.orders.map(order => order.id) : [];
            let index = orderIds.indexOf(order.id);
            orderIds.splice(index, 1);
            if (index >= orderIds.length) {
                orderIds.splice(0, 0, order.id);
            } else {
                orderIds.splice(index + 1, 0, order.id);
            }
            this.setOrders(orderIds);
        },
    }
}
</script>

<style scoped>
.fixed-table-area {
    width: 538px;
    min-width: 538px;
    max-width: 538px;
    margin: 16px 0 0 0;
    padding: 0;
}

.scrollable-area-wrapper {
    overflow-y: hidden;
    overflow-x: scroll;
}

.top-scrollbar-wrapper {
    overflow-y: hidden;
    overflow-x: scroll;
    height: 16px;
}

.scrollable-table-area {
    width: calc(100% - 538px);
    margin: 0;
    padding: 0;
}

.top-scrollbar {
    height: 16px;
}

.inventory-forecast-table {
    table-layout: fixed;
    margin: 0;
}

.inventory-forecast-table > thead > tr > th {
    height: 75px;
    text-align: center;
}

.inventory-forecast-table > thead > tr > th div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inventory-forecast-table > tbody > tr > td {
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inventory-forecast-table > tbody > tr.selected-row {
    outline: 1px solid darkslategray;
    background: lightgreen;
}
</style>
